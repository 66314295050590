<template>
  <div>
    <!--begin::customer-->


    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.full_name') }}<span class="text-danger">*</span></label>
        <input type="text" v-model="data.fullname" class="form-control"
               :class="validation && validation.fullname ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.fullname" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.fullname[0] }}
                        </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.customer_type') }}<span class="text-danger">*</span></label>
        <b-form-group :label="''" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="data.customer_type" :aria-describedby="ariaDescribedby" name="customer_type" value="1">{{ $t('customers.individual') }}</b-form-radio>
          <b-form-radio v-model="data.customer_type" :aria-describedby="ariaDescribedby" name="customer_type" value="2">{{ $t('customers.business') }}</b-form-radio>
        </b-form-group>
        <span v-if="validation && validation.customer_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.customer_type[0] }}
                                    </span>
      </div>

      <template v-if="data.customer_type == 2">
        <div class="col-lg-6 mb-5">
          <label>{{ $t('customers.commercial_register') }}</label>
          <input type="text" v-model="data.commercial_register" class="form-control" :class="validation && validation.commercial_register ? 'is-invalid' : ''"/>
          <span v-if="validation && validation.commercial_register" class="fv-plugins-message-container invalid-feedback">
                           {{ validation.commercial_register[0] }}
                           </span>
        </div>
        <div class="col-lg-6 mb-5">
          <label>{{ $t('customers.tax_register') }}</label>
          <input type="text" v-model="data.tax_register" class="form-control" :class="validation && validation.tax_register ? 'is-invalid' : ''"/>
          <span v-if="validation && validation.tax_register" class="fv-plugins-message-container invalid-feedback">
                           {{ validation.tax_register[0] }}
                           </span>
        </div>
      </template>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.email') }}</label>
        <input type="email" v-model="data.email" class="form-control"
               :class="validation && validation.email ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.email[0] }}
                        </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.phone') }}</label>
        <!-- <input type="number"  class="form-control" :class="validation && validation.phone ? 'is-invalid' : ''"  v-model="data.phone"/> -->
        <vue-tel-input v-model="data.phone" v-on:country-changed="phoneChanged"
                       :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>
        <span v-if="validation && validation.phone" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.phone[0] }}
                        </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.mobile') }}</label>
        <div class="input-group">
          <!-- <input type="number" class="form-control" v-model="data.mobile" /> -->
          <vue-tel-input v-model="data.mobile" v-on:country-changed="mobileChanged"
                         :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
          <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.mobile[0] }}
                        </span>
        </div>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('suppliers.country') }}</label>
        <multiselect v-model="country"
                     :placeholder="$t('suppliers.country')"
                     label="name" track-by="code2"
                     :options="countries"
                     :multiple="false"
                     :taggable="false"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false"
                     @search-change="getCountries($event)"
                     :internal-search="false">
        </multiselect>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('suppliers.country') }}.</span>

        <span v-if="validation && validation.country" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.country[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('suppliers.city') }}</label>
        <multiselect v-model="city"
                     :placeholder="$t('suppliers.city')"
                     label="name" track-by="id"
                     :options="cities"
                     :multiple="false"
                     :taggable="false"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false">
        </multiselect>
        <span v-if="validation && validation.city" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.city[0] }}
                            </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.state') }}</label>
        <div class="input-group">
          <input
              type="text"
              class="form-control"
              :class="
                                  validation && validation.state ? 'is-invalid' : ''
                                  "
              v-model="data.state"
          />

          <span
              v-if="validation && validation.state"
              class="fv-plugins-message-container invalid-feedback"
          >
                              {{ validation.state[0] }}
                              </span>
        </div>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.postal_code') }}</label>
        <div class="input-group">
          <input
              type="text"
              class="form-control"
              :class="
                                  validation && validation.postal_code
                                  ? 'is-invalid'
                                  : ''"
              v-model="data.postal_code"
          />
          <span
              v-if="validation && validation.postal_code"
              class="fv-plugins-message-container invalid-feedback"
          >
                              {{ validation.postal_code[0] }}
                              </span>
        </div>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('national_address') }}</label>
        <div class="input-group">
          <input
              type="text"
              class="form-control"
              :class="
                                  validation && validation.address_1 ? 'is-invalid' : ''
                                  "
              v-model="data.address_1"
          />
          <span
              v-if="validation && validation.address_1"
              class="fv-plugins-message-container invalid-feedback"
          >
                              {{ validation.address_1[0] }}
                              </span>
        </div>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.address_2') }}</label>
        <div class="input-group">
          <input type="text" class="form-control" :class=" validation && validation.address_2 ? 'is-invalid' : ''"
                 v-model="data.address_2"/>
          <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.address_2[0] }}
                              </span>
        </div>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.sales_man') }}</label>
        <multiselect v-model="sales_man"
                     :placeholder="$t('customers.sales_man')"
                     label="name"
                     track-by="id"
                     :options="employees"
                     :multiple="false"
                     :taggable="false"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false">
        </multiselect>
        <span v-if="validation && validation.sales_man_id" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.sales_man_id[0] }}
                    </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('status') }}</label>
        <div class="input-group">
          <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
        </div>
      </div>

    </div>

    <div>
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-customer-modal",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    type: null,
    customerId: null,
  },
  data() {
    return {
      mainRoute: 'customers/customers',
      mainRouteDependency: 'base/dependency',
      data: {
        phone: "",
        mobile: "",
        phone_country_code: null,
        mobile_country_code: null,
        country_code: "",
        city: "",
        state: "",
        postal_code: "",
        address_1: "",
        address_2: "",
        is_active: true,
        fullname: "",
        email: "",
        customer_code: "",
        share_with_client: 0,
        sales_man_id: null,

        customer_type: 1,
        commercial_register: null,
        tax_register: null,
      },
      sales_man: null,
      employees: [],
      countries: [],
      cities: [],
      validation: null,
      country: "",
      city: "",
      tab_name: 'basic_information',
      is_pos: null,
    };
  },
  watch: {
    country: function (val) {
      if (val) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
      }
    },
    city: function (val) {
      if (val) {
        this.data.city = val.id;
      } else {
        this.data.city = null;
      }
    },
    // 'data.fullname': function (val) {
    //   if (val) {
    //     this.data.fullname = val.replace(/[\d]/g, "");
    //   }
    // },
    'data.state': function (val) {
      if (val) {
        this.data.state = val.replace(/[\d]/g, "");
      }
    },
    sales_man: function (val) {
      if (val) {
        this.data.sales_man_id = val.id;
      } else {
        this.data.sales_man_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.customerId)
        this.update();
      else
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      // this.data.is_active = this.data.is_active ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        tab_name: this.tab_name,
        is_pos: this.is_pos,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.finishedSave(response.data.data);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      let that = this;
      let _data = this.data;

      ApiService.put(`${this.mainRoute}/${this.customerId}`, {
        tab_name: that.tab_name,
        is_pos: this.is_pos,
        ..._data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.finishedSave(response.data.data);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    async getData() {
      if (this.customerId)
        ApiService.get(`${this.mainRoute}/${this.customerId}`).then((response) => {
          this.data.fullname = response.data.data.fullname;
          this.data.phone = response.data.data.phone;
          this.data.mobile = response.data.data.mobile;
          this.data.phone_country_code = response.data.data.phone_country_code;
          this.data.mobile_country_code = response.data.data.mobile_country_code;
          this.data.country_code = response.data.data.country_code;
          this.data.city = response.data.data.city;
          this.data.state = response.data.data.state;
          this.data.postal_code = response.data.data.postal_code;
          this.data.address_1 = response.data.data.address_1;
          this.data.address_2 = response.data.data.address_2;
          this.data.is_active = response.data.data.is_active;
          this.data.fullname = response.data.data.fullname;
          this.data.email = response.data.data.email;
          this.data.customer_code = response.data.data.customer_code;
          this.data.share_with_client = response.data.data.share_with_client;
          this.data.sales_man_id = response.data.data.sales_man_id;
          this.data.customer_type = response.data.data.customer_type;
          this.data.commercial_register = response.data.data.commercial_register;
          this.data.tax_register = response.data.data.tax_register;

          this.sales_man = response.data.data.sales_man;
          this.country = response.data.data.country;
          this.city = response.data.data.city;

        });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data.filter(row => row.name != null);
        });
    },
    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getEmployees() {
      ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
        this.employees = response.data.data;
      });
    },

    resetAfterSave() {
      this.data.phone = '';
      this.data.mobile = '';
      this.data.country_code = '';
      this.data.city = '';
      this.data.state = '';
      this.data.postal_code = '';
      this.data.address_1 = '';
      this.data.address_2 = '';
      this.data.is_active = true;
      this.data.fullname = '';
      this.data.email = '';
      this.data.customer_code = '';
      this.data.sales_man_id = null;
      this.sales_man = null;
      this.customer_type = 1;
      this.commercial_register = null;
      this.tax_register = null;
      this.phone_country_code = null;
      this.mobile_country_code = null;
    },
    phoneChanged(obj) {
      if (obj) {
        this.data.phone_country_code = obj.dialCode
      } else {
        this.data.phone_country_code = null;
      }
    },
    mobileChanged(obj) {
      if (obj) {
        this.data.mobile_country_code = obj.dialCode;
      } else {
        this.data.mobile_country_code = null;
      }
    },

  },

  mounted() {
    // this.getCountries();
    this.getEmployees();
    this.is_pos = this.type;

    if (this.customerId) {
      this.getData()
    }
  },
};
</script>
